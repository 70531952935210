.zitron-list-menu-card {
  padding: 0;
  overflow: hidden;
  margin-top: 24px;
  height: 100%;

  .title {
    color: #667085;
    font-weight: 600;
    font-size: 12px;

    align-items: center;
  }

  .expand-icon {
    transition: transform 0.3s ease-in-out;

    &.is-collapse {
      transform: rotateZ(180deg) !important;
    }
  }
  
  .zitron-list-menu__button-group {
    display: flex;
    gap: 8px;
  }
  
  .custom-card {
    border-radius: 0;
    border: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    grid-gap: 0;
    gap: 0;
  }
}

.zitron-list-menu--list {
  border-right: 1px solid #eaecf0;
  width: 100%;

  .zitron-list-menu--list__header {
    border-bottom: 1px solid #eaecf0;
    padding: 8px 16px;
    flex: 0 1 auto;
  }

  .zitron-list-menu--list__menu {
    flex: 1 1 auto;
    background-color: #f9fafb;
    padding: 8px 16px;
    overflow-y: auto;

    div {
      font-size: 14px;
      border-radius: 20px;
      cursor: pointer;

      &.active {
        background-color: #f7e8e8;
        color: #cb171a;
      }
    }
  }
}

.zitron-list-menu--content {
  display: grid;
  padding: 18px 16px;
  gap: 16px;
  width: 100%;
  overflow: auto;
  
  .zitron-list-menu--content__title {
    display: flex;
    font-size: 16px;
    color: #667085;
    font-weight: 700;
    align-items: center;
  }

  .zitron-list-menu--content__card {
    margin-right: 32px;
    height: 100%;
    padding-bottom: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

}