.forget-pw-link {
  color: #000;
  background-color: transparent;
  font-weight: 600;

  &:hover {
    color: darken(#000, 15%);
  }
}

.login-landing-page {
  background: url("../../assets/latest-landing.jpg");
  background-size: cover;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-input {
  .ant-space-item{
    width: 100%;
  }

  .ant-input:hover {
    box-shadow: none;
  }

  .ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-error {
    border-color: #CB171A;
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
  .ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-error:focus,
  .ant-input-affix-wrapper.ant-input-password.ant-input-affix-wrapper-status-error:hover {
    border-color: #CB171A;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}

.username-input {
  .ant-space-item{
    width: 100%;
  }

  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
    border-color: #CB171A;
  }

  .ant-input:focus,
  .ant-input:hover {
    border-color: #CB171A;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}

.login-input {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  .ant-input-outlined:focus,
  .ant-input-outlined:hover {
    border-color: #CB171A;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: #CB171A;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
}

.login-disclaimer {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  margin-bottom: 0;
  padding: 8px;
  font-weight: 600;
}